import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './Nav.css';
import { device } from '../MainContents/Style/StyleDevice';
import logo from "../../img/logo200.png";

const HanberMenu = styled.div`
// パソコンは非表示　スマホは表示
display: block;
position: fixed;
right: 0%;
top: 0px;
width: 6.5rem;
height: 6.5rem;
border-bottom-left-radius: 10.5rem;
`;
const MainScope = styled.div`
// パソコンは非表示　スマホは表示
position: fixed;
right: 0px;
left: 0px;
z-index: 1;
top:0px;
}
@media ${device.mobile}{
  right: 0px;
 }
`;

const HanberBtn = styled.button`
    padding: 10px;
    position: absolute;
    top: 0.8rem;
    left: 2.3rem;
    width: 2.2rem;
    color: #ffffff;
`;

const ListMenuPc = styled.div`
    // パソコンは表示　スマホは非表示
    display: block; // 初期値をパソコンサイズでは表示に設定

    @media ${device.mobileM} {
        display: block; // スマートフォンサイズの場合は非表示
    }
    @media ${device.mobile} {
        display: none; // スマートフォンサイズの場合は非表示
    }
    @media ${device.ipadMiniA} and ${device.ipadMiniB}{
      display: block;
    }

`;


const UlNone = styled.ul`
// パソコンは表示　スマホは非表示
display: none;
@media ${device.mobile}{
    display: block;
}
@media ${device.ipadMiniA} and ${device.ipadMiniB}{
  display: none;
}
`;

const TestLogoStyle = styled.div`
    margin-top: 15px;
    padding-bottom: 10px;
    padding-left: 10px;
    // color: ghostwhite;
    font-size: 23px;
    @media ${device.mobile}{
      font-size: 15px;
    }
    color: #dark;
`;

const TelButtons = styled.button`

`;

const myComponentStyle = {
  textAlign: 'center', // 中央揃え
  WebkitAlignItems: 'center', // Safari用の中央揃え
  alignItems: 'center', // 他のブラウザ用の中央揃え
};

const mySpView = {
  height: '110vh',
  background: '#9b003f',
};
const mySpView2 = {
  background: '#ffffff',
};


const MenuSpan = styled.span`
text-align: -webkit-center;
align-items: center;
padding-left: 6px;
padding-bottom: 0px;
margin-bottom: -5px;
`
const Navber = () => {
  const url = '/tosayobi/';
  const [open, setContentState] = useState(false);
  const [selectedTab, setSelectedTab] = useState('home');
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // ナビバー表示・非表示　切替
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // 表示/非表示の制御期間を設定
    const startTime = new Date("2025-01-13T08:00:00"); // 開始時間
    const endTime = new Date("2025-02-28T23:59:59"); // 終了時間
    const currentTime = new Date();

    // 現在の時間が期間内かどうかを判定
    if (currentTime >= startTime && currentTime <= endTime) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []); // 初回レンダリング時のみ実行

  return (
    // <MainScope className='bg-regal-green'>
    <MainScope className="bg-white">
      <header className="container mx-auto font-bold text-black nav-background" style={open ? mySpView : mySpView2}>
        <div className="flex justify-between items-center">
          <TestLogoStyle>
            <Link to={'./'} onClick={() => { setContentState(!open); handleTabClick('Home'); }} ><img src={logo} alt="Top Image" class="" /></Link>
          </TestLogoStyle>

          {/* <h1 className="text-4xl font-semibold md:text-xl">HR</h1> */}
          {/* ハンバーガーメニュー */}
          <div className="block md:hidden">
            <HanberMenu className={open ? 'bg-white text-red-800' : 'bg-red-800'}>
              <HanberBtn onClick={() => setContentState(!open)} >
                <span className={open ? 'block' : 'hidden'} style={myComponentStyle}><svg className="h-7 w-7 text-red-800" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg></span>
                <MenuSpan className={open ? 'hidden' : 'block'} style={myComponentStyle}><svg className="h-5 w-5 fill-current" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" /></svg></MenuSpan>
                <span className={open ? 'text-sm text-red-800' : 'text-sm'}>menu</span>
              </HanberBtn>
            </HanberMenu>
          </div>
        </div>

        {/* PC tablet */}
        <ListMenuPc>
          <ul className="md:flex md:justify-around">
            <Link to={'./'} onClick={() => { setContentState(!open); handleTabClick('Home'); }} className="border-b md:border-none">
              <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'Home' ? 'bg-regal-hover-red text-white' : ''}`}>
                ホーム
              </a>
            </Link>
            <Link to={'Introductions'} onClick={() => { setContentState(!open); handleTabClick('Introductions'); }} className="border-b md:border-none">
              <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'Introductions' ? 'bg-regal-hover-red text-white' : ''}`}>
                コース
              </a>
            </Link>
            <Link to={'Success'} onClick={() => { setContentState(!open); handleTabClick('Success'); }} className="border-b md:border-none">
              <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'Success' ? 'bg-regal-hover-red text-white' : ''}`}>
                合格実績
              </a>
            </Link>
            {isVisible && (
             <Link to={'NijiTaisaku'} onClick={() => { setContentState(!open); handleTabClick('NijiTaisaku'); }} className="border-b md:border-none">
             <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'NijiTaisaku' ? 'bg-regal-hover-red text-white' : ''}`}>
               二次対策
             </a>
           </Link>
            )}

            <Link to={'Support'} onClick={() => { setContentState(!open); handleTabClick('Support'); }} className="border-b md:border-none">
              <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'Support' ? 'bg-regal-hover-red text-white' : ''}`}>
                サポート
              </a>
            </Link>
            <Link to={'Schedule'} onClick={() => { setContentState(!open); handleTabClick('Schedule'); }} className="border-b md:border-none">
              <a className={`block px-8 py-2 my-4 text-black hover:bg-regal-hover-red hover:text-white rounded ${selectedTab === 'Schedule' ? 'bg-regal-hover-red text-white' : ''}`}>
                年間スケジュール
              </a>
            </Link>

          </ul>
        </ListMenuPc>

        {/* mobile */}
        <div className={open ? 'block mt-8' : 'hidden'}>
          <UlNone className="md:flex md:justify-around text-lg">
            <Link to={'./'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                ホーム<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            <Link to={'Introductions'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                コース<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            <Link to={'Success'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                合格実績<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            {isVisible && (
            <Link to={'NijiTaisaku'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                二次対策<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            )}
            <Link to={'Support'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                サポート<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            <Link to={'Schedule'} onClick={() => setContentState(!open)} className="border-b md:border-none ">
              <a className="w-11/12 mx-auto block px-8 py-4 my-4 text-white hover:bg-regal-hover-red hover:text-white rounded border-b-2 border-white">
                年間スケジュール<span className="material-symbols-outlined align-middle float-right">chevron_right</span>
              </a>
            </Link>
            <div className='mt-8 text-center'>
              <TelButtons className='px-6 py-3.5 text-base font-medium text-red-700  bg-white focus:ring-4 focus:outline-none  rounded-lg text-center border-2 border-white'>
                <span class="material-symbols-outlined align-middle pr-3">&nbsp;phone_in_talk</span>TEL(088-883-1065)
              </TelButtons>
            </div>
          </UlNone>
        </div>
      </header>
    </MainScope>
  );
}
export default Navber;