const UniversityTable = () => {
    return (
      <div className="relative">
        {/* 背景の桜SVG */}
        <div className="absolute inset-0 flex justify-center items-center opacity-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 24 24"
            fill="pink"
          >
            <path d="M12 2c1.1 0 2 .9 2 2 0 .45-.15.86-.4 1.2.58-.12 1.22-.2 1.88-.2 2.21 0 4 1.79 4 4 0 .99-.37 1.9-.98 2.6.2-.03.4-.06.6-.06 1.1 0 2 .9 2 2 0 1.24-.95 2.25-2.17 2.38 0 .07.01.14.01.22 0 2.21-1.79 4-4 4-1.06 0-2.03-.42-2.74-1.1-.71.68-1.68 1.1-2.74 1.1-2.21 0-4-1.79-4-4 0-.08.01-.15.01-.22C4.95 16.25 4 15.24 4 14c0-1.1.9-2 2-2 .2 0 .4.03.6.06-.61-.7-.98-1.61-.98-2.6 0-2.21 1.79-4 4-4 .66 0 1.3.08 1.88.2A1.99 1.99 0 0112 2z" />
          </svg>
        </div>
  
        {/* 国公立大学テーブル */}
        <div className="mb-10">
          <h2 className="text-2xl font-bold text-red-600 text-center mb-4">国公立大学 合計 832名</h2>
          <table className="table-auto border-collapse border border-red-300 w-full text-left">
            <thead>
              <tr className="bg-red-500 text-white">
                <th className="border border-red-300 px-4 py-2">大学名</th>
                <th className="border border-red-300 px-4 py-2">人数</th>
              </tr>
            </thead>
            <tbody>
              {[
                ["東京大", "3名"],
                ["京都大", "17名"],
                ["大阪大", "22名"],
                ["旧七帝大", "61名"],
                ["筑波大", "7名"],
                ["神戸大", "18名"],
                ["医学部医学科", "57名"],
                ["歯・薬・獣医", "21名"],
                ["高知大", "229名"],
                ["高知県立大", "53名"],
                ["高知工科大", "75名"],
                ["愛媛大", "59名"],
                ["香川大", "43名"],
                ["徳島大", "40名"],
              ].map((row, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-red-100" : "bg-white"}>
                  <td className="border border-red-300 px-4 py-2">{row[0]}</td>
                  <td className="border border-red-300 px-4 py-2">{row[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* 私立大学テーブル */}
        <div>
          <h2 className="text-2xl font-bold text-red-600 text-center mb-4">私立大学 合計 3,332名</h2>
          <table className="table-auto border-collapse border border-red-300 w-full text-left">
            <thead>
              <tr className="bg-red-500 text-white">
                <th className="border border-red-300 px-4 py-2">大学名</th>
                <th className="border border-red-300 px-4 py-2">人数</th>
              </tr>
            </thead>
            <tbody>
              {[
                ["早稲田大", "29名"],
                ["慶應義塾大", "8名"],
                ["上智大", "4名"],
                ["東京理科大", "27名"],
                ["MARCH（明治・青山・立教・中央・法政）", "200名"],
                ["関関同立（関西学院・関西・同志社・立命館）", "599名"],
                ["日東駒専（日本・東洋・駒澤・専修）", "138名"],
                ["産近甲龍（京都産業・近畿・甲南・龍谷）", "700名"],
              ].map((row, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-red-100" : "bg-white"}>
                  <td className="border border-red-300 px-4 py-2">{row[0]}</td>
                  <td className="border border-red-300 px-4 py-2">{row[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default UniversityTable;