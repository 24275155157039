import React from "react";
import styled from "styled-components";
import { device } from '../MainContents/Style/StyleDevice';
const DivTop = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;
  @media ${device.mobile} {
    width: 100%;
  }
`

const FlashReport = props => {
    const { titleSokuhou, postingSokuhou, schoolData } = props; // schoolDataは大学名と合格者数の配列
    return (
        <DivTop>
            <div className="bg-white p-6 border rounded-lg shadow-md">
                <h1 className="text-4xl font-bold text-red-700 border-b-4 border-red-500 pb-4 mb-6">
                    {titleSokuhou}
                </h1>
                <h2 className="text-xl font-semibold text-gray-800 pb-2">
                    {postingSokuhou}
                </h2>
                <p className="text-sm text-gray-600 mb-4">
                    ※現役部門は含んでいません。
                </p>
                <ul className="list-disc pl-5 text-lg text-gray-800">
                    {schoolData.map((school, index) => (
                        <li key={index}>
                            <span className={`font-bold text-2xl ${school.name === "京都大" ? "text-red-600" : ""}`}>
                                {school.name}:<span className="pl-2 text-3xl">{school.count}</span> 名
                            </span>
                        </li> 
                    ))}
                </ul>
            </div>
        </DivTop>
    );
}

export default FlashReport;
