import React from 'react';
import styled from 'styled-components';
import { device } from '../MainContents/Style/StyleDevice';
import test1 from "../../img/test_1 (1).jpg";
import support1 from "../../img/test_1 (2).jpg";
import support2 from "../../img/test_1 (3).jpg";
import support3 from "../../img/test_1 (4).jpg";

const Container = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 170px;
    text-align: center;
    @media ${device.mobile} {
        margin-top: 80px;
    }
`;

const Title = styled.h2`
    font-size: 2.5rem;
    font-weight: bold;
    color: #9b003f;
    margin-bottom: 20px;
`;

const Description = styled.p`
    font-size: 1.2rem;
    margin-bottom: 50px;
    line-height: 1.8;
`;

const Section = styled.div`
    margin-bottom: 80px;
`;

const PointContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    background-color: #f8f8f8;
    padding: 50px 20px;
`;

const PointItem = styled.div`
    width: 30%;
    min-width: 250px;
    text-align: center;
    @media ${device.mobile} {
        width: 100%;
    }
`;

const PointTitle = styled.h3`
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
`;

const CourseContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    text-align: center;
`;

const CourseTitle = styled.h3`
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 4px solid rgba(155, 0, 63, 0.5);
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 30px;
`;

const CourseItem = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    @media ${device.mobile} {
        width: 150px;
        height: 150px;
        font-size: 1.2rem;
    }
`;

const RedCircle = styled(CourseItem)`
    background-color: #9b003f;
    color: white;
    font-size: 20px;
`;

const WhiteCircle = styled(CourseItem)`
    background-color: white;
    border: 2px solid #9b003f;
    color: #9b003f;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
`;

const Button = styled.a`
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: background 0.3s;
`;

const RedButton = styled(Button)`
    background-color: #9b003f;
    color: white;
    &:hover {
        background-color: #7a002f;
    }
`;

const WhiteButton = styled(Button)`
    background-color: white;
    border: 2px solid #9b003f;
    color: #9b003f;
    &:hover {
        background-color: #f8f8f8;
    }
`;

const SupportSchools = () => {
    return (
        <Container>
            <img src={test1} alt="Top Image" className="w-full opacity-40" />
            <Title>土佐塾予備校 × 第一高等学院</Title>
            <Description>
                一人ひとりの生徒に向き合い、個々の力に寄り添いながら、生徒が安心して学びに集中できる環境を作り、
                そして徹底した個別指導で講師がサポートします。
            </Description>

            {/* ポイント紹介 */}
            <PointContainer>
                <PointItem>
                    <PointTitle>Point 01 学習指導</PointTitle>
                    <img src={support1} alt="学習指導" />
                    <p>個別に対応し、生徒の学力向上を支援します。</p>
                </PointItem>
                <PointItem>
                    <PointTitle>Point 02 進路相談</PointTitle>
                    <img src={support2} alt="進路相談" />
                    <p>将来の進路を共に考え、最適な道を提案します。</p>
                </PointItem>
                <PointItem>
                    <PointTitle>Point 03 高等学校の連携</PointTitle>
                    <img src={support3} alt="高等学校の連携" />
                    <p>高等学校と連携し、柔軟な学習環境を提供します。</p>
                </PointItem>
            </PointContainer>

            {/* コース紹介 */}
            <Section>
                <CourseTitle>選べる２つの進学コース</CourseTitle>
                <CourseContainer>
                    <RedCircle>Mobile HighSchool (オンライン)</RedCircle>
                    <WhiteCircle>スタンダードコース</WhiteCircle>
                </CourseContainer>

            </Section>

            <Section>
            <ButtonContainer>
                    <RedButton href="#">詳しくはこちら</RedButton>
                    <WhiteButton href="#">資料請求はこちら</WhiteButton>
                </ButtonContainer>
            </Section>
        </Container>
    );
};

export default SupportSchools;
