import React from 'react'
import { Navbar, Main, Schedule, Introductions, Success, Footer, Support,SupportSchools, NijiTaisaku } from './index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

class App extends React.Component {

  render() {
    return (
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Schedule" element={<Schedule />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Introductions" element={<Introductions />} />
          <Route path="/Success" element={<Success />} />
          <Route path="/SupportSchools" element={<SupportSchools />} />
          <Route path="/NijiTaisaku" element={<NijiTaisaku />} />
        </Routes>
        <Footer />
      </Router>
    );
  }
}
export default App;