import React, { useState, useEffect } from 'react';
import "./Style/loadingAnimation.css";
import Loading from './Loading';
import styled from 'styled-components';
import Topics from './Topics';
import DocumentRequest from './DocumentRequest';
import Access from './Access';
import Approach from './Approach';
import { device } from '../MainContents/Style/StyleDevice';
// import topImagePc from "../../img/course2.jpeg";
// import topImageSp from "../../img/course2sp.jpeg";
import topImagePc from "../../img/newStudent.jpg";
import topImageSp from "../../img/newStudentSp.jpg";

const DivTop = styled.div`
margin-top: 170px;
@media ${device.mobile}{
    margin-top: 80px;
   }
`;

const HeadingTitle = styled.div`
  text-align: left;
  font-size: 55px;
  font-weight: 500;
  line-height: 1.5em;
  color: #9b003f;
  margin-bottom: 30px;
  @media ${device.mobile} {
    font-size: 26px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 18px;
  }
`;

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageSrc = windowWidth <= 768 ? topImageSp : topImagePc; // 768pxをスマホとパソコンの画像切り替えの基準に
    return (
        <DivTop>
            <Loading />

            <div class="relative">
              <img src={imageSrc} alt="Top Image" class="w-full" />
              {/* <img src={imageSrc} alt="Top Image" class="w-full opacity-70" /> */}
              {/* <HeadingTitle className="absolute tracking-widest p-4" style={{ left: '10%', top: '15%' }}>
                      受験に燃える。<br />
                      自分を育てる。<br />
              </HeadingTitle> */}
            </div>

            <Topics />
            <Approach />
            <DocumentRequest />
            <Access />
        </DivTop>
    );
}

export default Main;
